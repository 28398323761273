import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../style/style.scss"
import AnekdotesLogo from "../img/anekdotes-logo.svg"

export default function Home() {
  return (
    <div>
      <Header></Header>
      <body>
        <section class="section">
          <div class="container">
            <img src={AnekdotesLogo} className="logo"></img>
          </div>
        </section>
        <section class="section">
          <div class="container">
            <div className="columns">
              <div className="column is-one-third"></div>
              <div className="column">
                <h2>About Us</h2>

                <p>
                  ANEKDOTES, founded by Abbhinav Kastura, is a Digital Content
                  Studio that develops Web Shows and Film scripts. We have a
                  roster of OTT/ Film writers who supervise a dedicated 'Mini
                  Writers Room’ that is flexible, efficient and delivers quality
                  projects that suit the requirements of the Production House
                  and OTT Platforms.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="container">
            <div className="columns">
              <div className="column is-one-third"></div>
              <div className="column">
                <h3>Founder</h3>
                <h2>Abbhinav Kastura</h2>

                <p>
                  Since 2015, Abbhinav has been in the Video production
                  Business. He started off with TV Advertising and then moved to
                  YouTube Channel space during the digital Boom. He worked as
                  the Senior Producer for Culture Machine's YT Channel - Put
                  Chutney where he produced - a web series called CTRL ALT DEL
                  (now on HOTSTAR) and sketch comedy show called ICU (Star
                  Vijay). He has also produced more than 200 sketch videos for
                  the Channel, being integral in building its subscriber base
                  from 200,000 to 1 Million.
                  <br></br>
                  <br></br>In 2018, he moved on to work as a Supervising
                  Producer for Viu and was instrumental in launching the OTT
                  platform VIU in Tamil and supervised the rollout of 8 of their
                  projects that year.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="container">
            <div className="columns">
              <div className="column is-one-third"></div>
              <div className="column">
                <h3>Creative Director</h3>
                <h2>Navaneeth Sriram</h2>

                <p>
                  Navaneeth Sriram is a writer and creative consultant based in
                  Bangalore, India. With 5 years of experience as a writer,
                  theatre artist and playwright, he has worked in the Tamil and
                  Telugu film industries. He wrote and created the first season
                  of the first web series mockumentary in India - 'Better Life
                  Foundation'. He was also part of the writing team of the
                  Amazon Prime show 'Pushpavalli'. Currently he is working on a
                  Tamil Amazon series, two tamil features and a hindi Web
                  series. He is also a creative consultant for a few directors
                  in the Tamil and Telugu film industry. Adept at story and
                  structure, his strengths lie in early development and
                  packaging of web series and movies.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="container">
            <div className="columns">
              <div className="column is-one-third"></div>
              <div className="column">
                <h2>Partnerships</h2>
                <p>
                  Our collective of writers are working on original scripts for
                  production houses like OML, SHVEDH, SUMUKHA BODHITREE, CULTURE
                  MACHINE and VIKATAN just to name a few.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </body>
    </div>
  )
}
